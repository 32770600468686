import {ORDER_INBOUND_STATUS, ORDER_INBOUND_TYPE, USPS_SHIPPING_COMPANY} from "../../staticData/staticVariables";
import {ordersInboundProductMixin} from "./ordersInboundProductMixin";
import {ordersInboundDeliveryMixin} from "./ordersInboundDeliveryMixin";

export const ordersInboundMixin = {
	mixins: [
		ordersInboundProductMixin,
		ordersInboundDeliveryMixin,
	],

	computed: {
		getUserId() {
			return this.ordersInbound.data.User.getUserId()
		},

		returnType() {
			return this.ordersInbound.data.returnType
		},

		getStatusCreateLabel() {
			return Object.keys(this.ordersInbound.data.labelPrice).length > 0
		},
	},

	methods: {

		recalculateTotalOrderPrice() {
			if(this.ordersInbound.getAdminChangedTotalOrderProcessPrice() || !this.ordersInbound.getUseTotalOrderProcessPrice()) {
				return
			}

			let orderPrice = this.ordersInbound.getOrderProcessPrice()
			if(orderPrice && Number(orderPrice.cost) > 0) {
				let productCount = 0
				this.ordersInbound.data.productsForCellArray.map(product => {
					productCount += product.data.productCount
				})

				this.ordersInbound.setTotalOrderProcessPrice(parseFloat(Number(orderPrice.cost) * productCount).toFixed(2))
				// if(productCount > 3) {
				// 	this.ordersInbound.setTotalOrderProcessPrice(parseFloat(Number(orderPrice.cost) * productCount).toFixed(2))
				// } else {
				// 	this.ordersInbound.setTotalOrderProcessPrice(parseFloat(0).toFixed(2))
				// }
			}
		},

		getLabelPriceFromCard() {
			if (this.isAdmin && !this.ordersInbound.adminValidation(false)) return

			let prepareData = this.ordersInbound.prepareSave({isAdmin: this.isAdmin, edit: false})

			let data = {
				'user_id': prepareData['user_id'],
				'recipient_company_name': prepareData['sender_company_name'],
				'recipient_contact_name': prepareData['sender_customer_name'],
				'recipient_phone_number': prepareData['sender_phone'],
				'recipient_address': prepareData['sender_address'],
				'recipient_address_1': prepareData['sender_address_line_2'],
				'recipient_city': prepareData['sender_city'],
				'recipient_region': prepareData['sender_region'],
				'recipient_country_id': prepareData['sender_country_id'],
				'recipient_zip_code': prepareData['sender_zip'],
				'comment': prepareData['comment'],
				'insurance_amount': null,
				'dimension': [{
					'weightLb': prepareData['weight_lb'],
					'weightOz': prepareData['weight_oz'],
					'height': null,
					'width': null,
					'length': null,
				}],
			}

			this.$store.dispatch('getInboundLabelRate', data).then(response => {

				switch (this.getResponseStatus(response)) {
					/**
					 * Success
					 */
					case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
						let dataArray = this.getRespData(response)
						if(dataArray.length === 0) {
							this.openNotify('error', 'common_notificationNothingFound')
							return
						}

						let data = this._.first(this.getRespData(response))
						this.ordersInbound.data.labelPrice = {
							insurance: parseFloat(data.insuranceCost) > 0 ? parseFloat(data.insuranceCost).toFixed(2) : null,
							price: parseFloat(data.shipmentCost).toFixed(2),
							total_price: parseFloat(parseFloat(data.shipmentCost) + parseFloat(data.insuranceCost)).toFixed(2),
							rate: data
						}
						break
					}
					/**
					 * Validation Error
					 */
					case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
						let errors = response.response.data.errors;
						this.notifyErrorHelper(errors)
						break
					}
					/**
					 * Undefined Error
					 */
					default: {
						this.openNotify('error', 'common_notificationUndefinedError')
					}
				}

			})

		},

		checkPermission(){
			return this.checkOrderTypePermission({
				types: {
					[ORDER_INBOUND_TYPE.FBM.value]: this.PERMISSIONS.ORDER_INBOUND_FBM,
					[ORDER_INBOUND_TYPE.FBA.value]: this.PERMISSIONS.ORDER_INBOUND_FBA,
					[ORDER_INBOUND_TYPE.FBMReturn.value]: this.PERMISSIONS.ORDER_INBOUND_RETURNS,
					[ORDER_INBOUND_TYPE.FBAReturn.value]: this.PERMISSIONS.ORDER_INBOUND_RETURNS,
				},
				currentType: this.$route.params.type
			})
		},

		checkReturnCreatePermission() {
			if (this.$route.params.type === ORDER_INBOUND_TYPE.FBMReturn.value || this.$route.params.type === ORDER_INBOUND_TYPE.FBAReturn.value) {
				return this.checkOrderTypePermission({
					types: {
						[ORDER_INBOUND_TYPE.FBMReturn.value]: this.PERMISSIONS.ORDER_FBM_GENERAL,
						[ORDER_INBOUND_TYPE.FBAReturn.value]: this.PERMISSIONS.ORDER_FBA_GENERAL,
					},
					currentType: this.$route.params.type
				})
			}
			return true
		},

		initialProformsForUser(order) {
			if (Object.keys(order['products']).length > 0) {
				Object.keys(order['products']).map((item,index) => {
					/**
					 * Generate data product and add to proformProduct model
					 **/
					let currentProduct = order['products'][index]?.product
					let proformDataEntities = order['productProformData'][index]?.proformData
					// let proformDataEntities = this._.find(order['productProformData'], {product_id: currentProduct?.id}).proformData
					let entityId = proformDataEntities ? Object.keys(proformDataEntities)[0] : 0
					let proformData = null
					if (entityId > 0) {
						proformData = proformDataEntities[entityId]
					}
					else {
						proformData = {
							proform_category: '',
							proformOptions: {
								handmade: '1',
								item_price: 0,
								item_quantity: order['products'][index]?.quantity,
							},
							proformTypes: null,
							values: [],
						}
					}

					let productData = {
						productInstance: currentProduct,
						proformData: proformData,
					}
					this.ordersInbound.data.ProformProducts[index].setProductItem(productData, entityId, this)

					this.ordersInbound.data.ProformProducts[index].setHSCode(proformData.proformOptions.hs_code)
					this.ordersInbound.data.ProformProducts[index].setProductItemsQuantity(order['products'][index]?.quantity)

					// if last product
					if (Object.keys(this.ordersInbound.data.ProformProducts).length === order['products'].length) return
					this.addProformProduct(this.ordersInbound.data.ProformProducts)
				})
			}

			this.ordersInbound.data.ProformProducts.map((itemProduct) => {
				this.initialProformSelectOptions({
					val: itemProduct.product.proformData.proformCategory,
					item: itemProduct
				})
			})
		},


		checkChangeReturnType() {
			if(this.returnType === this.ordersInbound.data.returnTypes.iHasLabel){
				this.ordersInbound.setDeliveryServicesId('')
			}
			if(this.returnType === this.ordersInbound.data.returnTypes.buyLabel){
				this.ordersInbound.setDeliveryServicesId(USPS_SHIPPING_COMPANY.id)
			}
		},

		/**
		 * Change User
		 */
		changeUser(user, resetProduct = true) {
			this.ordersInbound.data.User.setUserData(user)
			if (resetProduct){
				this.ordersInbound.resetProformProducts()
				this.initializeProductProformArray(this.ordersInbound.data.ProformProducts)
			}
		},

		saveInDraft(arg) {
			let confirm = () => {
				this.save(arg)
				this.$store.commit('setSaveInDraftsPopup', false)
				return true
			}

			this.saveInDraftsFunc(confirm)
		},

		save(arg = false) {
			if (arg.isAdmin && !this.ordersInbound.adminValidation(arg.edit, this)) return
			if (arg.isAdmin && this.ordersInbound.getStatus().id === ORDER_INBOUND_STATUS.STATUS_COMPLETE &&
				!this.ordersInbound.InboundCellCountsValidate(arg.edit)) {
				this.openNotify('error', 'common_ChooseProductCell')
				return
			}

			if(this.ordersInbound.data.AddedProducts.length > 0){
				this.productMassStore().then(() => {
					this.saveShipment(arg)
				})
				return
			}

			this.saveShipment(arg)
		},

		saveShipment(arg) {
			let data = this.ordersInbound.prepareSave({isAdmin: arg.isAdmin, edit: arg.edit})
			// data['products'] = arg.products

			let saveType = 'createOrderInbound'

			if (arg.isAdmin) {
				data['user_id'] = this.ordersInbound.data.User.getUserId()
			}
			if (arg.draft) {
				data['status_id'] = ORDER_INBOUND_STATUS.STATUS_DRAFT
			}

			if (arg.edit) {
				data = {
					id: this.ordersInbound.data.Order.getId(),
					data: data
				}
				saveType = 'updateOrderInbound'
			}

			this.$store.dispatch(saveType, data).then(response => {
				response.status = response?.data?.status || response?.response?.status

				switch (response.status) {
					/**
					 * Success
					 */
					case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
						let id = response.data.data.id

						if(this.ordersInbound.data.Documents.data.files.length > 0) {
							this.updateFiles(response.data.data.id).then(() => {
								if(arg.createLabel){
									this.createShipmentLabel(arg, id).then(() => {
										this.$router.push(this.$store.getters.GET_PATHS.ordersShipmentWarehouse + `?openLabel=${id}`)
									})
									return
								}

								if(this.isAdmin && this.ordersInbound.data.idReturned) {
									this.$router.push(this.$store.getters.GET_PATHS.ordersShipmentWarehouse)
								}
								else if(this.isAdmin) {
									this.reloadOrder()
								} else {
									this.$router.push(this.$store.getters.GET_PATHS.ordersShipmentWarehouse)
								}
								this.viewShipmentSuccessNotify(arg.edit)
							})
							return
						}

						if(arg.createLabel){
							this.createShipmentLabel(arg, id).then(() => {
								this.$router.push(this.$store.getters.GET_PATHS.ordersShipmentWarehouse + `?openLabel=${id}`)
							})
							return
						}

						this.viewShipmentSuccessNotify(arg.edit)

						if(this.isAdmin && this.ordersInbound.data.idReturned) {
							this.$router.push(this.$store.getters.GET_PATHS.ordersShipmentWarehouse)
						}
						else if(this.isAdmin) {
							this.reloadOrder()
						} else {
							this.$router.push(this.$store.getters.GET_PATHS.ordersShipmentWarehouse)
						}
						break
					}
					/**
					 * Validation Error
					 */
					case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
						let errors = response.response.data.errors;
						this.notifyErrorHelper(errors)
						break
					}
					/**
					 * Undefined Error
					 */
					default: {
						this.openNotify('error', 'common_notificationUndefinedError')
					}
				}
			})
		},

		createShipmentLabel(arg, id){
			return this.$store.dispatch('createInboundLabel', {order_id: id}).then((response) => {
				if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

				switch (this.getResponseStatus(response)) {
					/**
					 * Success
					 */
					case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
						this.viewShipmentSuccessNotify(arg.edit)
						break
					}
					/**
					 * Validation Error
					 */
					case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
						let errors = response.response.data.errors;
						this.notifyErrorHelper(errors)
						break
					}
					/**
					 * Undefined Error
					 */
					default: {
						this.openNotify('error', 'common_notificationUndefinedError')
					}
				}
			})
		},

		viewShipmentSuccessNotify(edit){
			if (edit) {
				this.openNotify('success', 'common_notificationRecordChanged')
			} else {
				this.openNotify('success', 'common_notificationRecordCreated')
			}
		},

		removeItem(id, showFlag = false){
			let text = {
				title: 'fba_DeleteOrder',
				txt: '',
				yes: 'common_confirmDelete',
				no: 'common_confirmNo'
			}

			let confirm = () => {

				this.$store.dispatch('deleteOrderInbound', id).then((response) => {
					if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

						this.$emit('reload')
						this.openNotify('success', 'common_notificationRecordDeleted')

					} else {
						setTimeout(() => {
							this.openNotify('error', 'common_notificationUndefinedError')
						}, 200)
					}
				})

				this.$store.commit('setConfirmDeletePopup', false)
				if(showFlag) {
					this.$router.push(this.$store.getters.GET_PATHS.ordersShipmentWarehouse)
				}
				return true
			}

			this.deleteFunc(text, confirm)
		},

		/**
		 * Documents block
		 */
		updateFiles(id) {
			let reader = new FormData();
			this.ordersInbound.data.Documents.getFiles().map((item) => {
				if(!this._.has(item, 'maxCount') && !item.maxSizeError){
					reader.append("file", item.file);
				}
			})
			reader.append("id", id);

			let data = {
				id: id,
				data: reader,
			}

			return this.$store.dispatch('uploadFileOrderInbound', data).then((response) => {

				if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
					// this.openNotify('success', 'common_notificationRecordCreated')
				} else if (response.response.data.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
					alert('novalid')
				} else {
					this.openNotify('error', 'common_notificationUndefinedError')
				}

			})
		},

		changeFile(files, maxSizeError) {
			this.ordersInbound.data.Documents.setFiles(files)
			this.ordersInbound.data.Documents.setFilesMaxSizeError(maxSizeError)

			// if (files.length === 0) return
			//
			// let file = files[0].file;
			// this.getBase64(file).then(data => {
			// 	let dataFile = Object.assign([], files[0])
			// 	let base64 = data.split('base64,')
			// 	dataFile.base64String = base64[1]
			// 	this.ordersInbound.data.Documents.setFiles([dataFile])
			// });
			//
			// this.ordersInbound.data.Documents.setFilesMaxSizeError(maxSizeError)
		},

		removeFile(id) {
			this.$store.dispatch('removeUserDocument', id).then(() => {
				this.ordersInbound.removeFile(id - 1)
			})
		},

		/**
		 * В момент инициализации ордера на создание или редактирования нужно определить,
		 * какой тип ордера и возврат ли это. За это отвечает два параметра
		 * fulfillment и return
		 */
		setOrderSystemTypesAndDeliverySystemByType(type, create = false) {
			let typeId = ''

			switch (type) {
				case ORDER_INBOUND_TYPE.FBM.value:
					typeId = ORDER_INBOUND_TYPE.FBM.id
					break
				case ORDER_INBOUND_TYPE.FBA.value:
					if(create) {
						this.$router.push(this.$store.getters.GET_PATHS.notFound)
					}
					typeId = ORDER_INBOUND_TYPE.FBA.id
					break
				case ORDER_INBOUND_TYPE.FBMReturn.value:
					typeId = ORDER_INBOUND_TYPE.FBMReturn.id
					break
				case ORDER_INBOUND_TYPE.FBAReturn.value:
					if(create) {
						this.$router.push(this.$store.getters.GET_PATHS.notFound)
					}
					typeId = ORDER_INBOUND_TYPE.FBAReturn.id
					break
			}

			/**
			 * в зависимости от типа добавляю в модель нужные данные fulfillment и return
			 */
			if (typeId === ORDER_INBOUND_TYPE.FBM.id || typeId === ORDER_INBOUND_TYPE.FBMReturn.id) {
				this.ordersInbound.setFulfillment(ORDER_INBOUND_TYPE.FBM.value)
			}
			if (typeId === ORDER_INBOUND_TYPE.FBA.id || typeId === ORDER_INBOUND_TYPE.FBAReturn.id) {
				this.ordersInbound.setFulfillment(ORDER_INBOUND_TYPE.FBA.value)
			}

			this.ordersInbound.setReturn(typeId === ORDER_INBOUND_TYPE.FBMReturn.id || typeId === ORDER_INBOUND_TYPE.FBAReturn.id)
			this.ordersInbound.setOrderInboundTypeId(typeId)


			/**
			 * Получаю службы доставки (DHL, FedEx, USPS и т.д.), которые могут быть использованы
			 */
			this.$store.dispatch('getOrderInboundType', typeId).then((response) => {
				this.ordersInbound.setDeliveryServices(this.getRespData(response)['delivery_services'])
			})
		},

		/**
		 * Получаю список "качество" для ордера, чтобы потом его записать в селект
		 */
		getProductsConditionFc() {
			this.$store.dispatch('getProductsCondition').then((response) => {
				this.ordersInbound.setCondition(this.getRespPaginateData(response))
			})
		},


		/**
		 * Функция для формирования url для редактирования
		 * @param fulfillment
		 * @param returnSign
		 */
		getOrderType(fulfillment, returnSign) {
			switch (true) {
				case fulfillment === ORDER_INBOUND_TYPE.FBM.value && !returnSign:
					return this._.find(ORDER_INBOUND_TYPE, {id: 1})
				case fulfillment === ORDER_INBOUND_TYPE.FBA.value && !returnSign:
					return this._.find(ORDER_INBOUND_TYPE, {id: 2})
				case fulfillment === ORDER_INBOUND_TYPE.FBM.value && returnSign:
					return this._.find(ORDER_INBOUND_TYPE, {id: 3})
				case fulfillment === ORDER_INBOUND_TYPE.FBA.value && returnSign:
					return this._.find(ORDER_INBOUND_TYPE, {id: 4})
			}
		},


		changeTrackNumber({value, id}) {
			this.$store.dispatch('addTrackNumOrderInbound', {
				id: id,
				data: {tracking_number: value}
			}).then((response) => {
				if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
					this.openNotify('success', 'common_notificationStatusChanged')
					let ordersInbound = this.$store.getters.getOrdersInbound
					this._.find(ordersInbound, {id: id}).tracking_number = value
				} else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
					let errors = response.response.data.errors;

					setTimeout(() => {
						this.openNotify('error', {txtServer: errors['tracking_number'][0]})
					}, 200)

				} else {
					this.openNotify('error', 'common_notificationUndefinedError')
				}
			})
		},

		downloadFiles(type) {
			let forPage, page, url, exportIds

			exportIds = this.getCheckedRows('row-name')

			// generate main filter if has get params in URL
			let generateMainFilter = this.generateMainFilterFromUrl(false, this)

			page = generateMainFilter.page
			forPage = generateMainFilter.forPage

			url = this.generateFilterUrl(page, forPage, exportIds)

			this.$store.dispatch('getOrderInboundExport', {filter: url, exportType: type}).then((response) => {
				if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
					const blob = new Blob([response.data], {type: `application/${type}`})
					const link = document.createElement('a')
					link.href = URL.createObjectURL(blob)
					link.download = `Shipment to warehouse.${type}`
					link.click()
					URL.revokeObjectURL(link.href)
				} else {
					setTimeout(() => {
						this.openNotify('error', 'common_notificationUndefinedError')
					}, 200)
				}
			})
		},


		nextPageHandler() {

			let valid = true

			// let flagStepC = (this.$route.params.type === ORDER_INBOUND_TYPE.FBMReturn.value)

			switch (this.activeStep) {
				case 1:
					valid = this.ordersInbound.stepAValidation({
						// deliveryServicesId: !flagStepC,
						trackingNumber: true,
						shippingDate: true
					})
					break
				case 2:
					valid = this.ordersInbound.stepBValidation(this)
					break
				case 3:
					valid = this.ordersInbound.stepCValidation({
						// deliveryServicesId: flagStepC,
						personal: this.ordersInbound.data.returnTypes.buyLabel === this.ordersInbound.data.returnType,
						delivery: this.ordersInbound.data.returnTypes.buyLabel === this.ordersInbound.data.returnType,
						weight: this.ordersInbound.data.returnTypes.buyLabel === this.ordersInbound.data.returnType,
					})
					break
			}

			if (valid)
				if (this.activeStep < this.progressStepsSetting.length) this.activeStep = this.activeStep + 1
		},
	}
}



export const ordersInboundTableMixin = {
	mixins: [ordersInboundMixin],

	methods: {
		isStatusDraft(item){
			return item['status_id'] === ORDER_INBOUND_STATUS.STATUS_DRAFT
		},

		isStatusInProgress(item){
			return item['status_id'] === ORDER_INBOUND_STATUS.STATUS_IN_PROGRESS
		},

		isStatusComplete(item){
			return item['status_id'] === ORDER_INBOUND_STATUS.STATUS_COMPLETE
		},
	}
}
