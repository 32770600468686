
export const ordersInboundDeliveryMixin = {

  // computed: {
  //   getOrderProcessPrice() {
  //     return this.ordersInbound?.data?.orderProcessPrice
  //   }
  // },
  //
  // watch: {
  //   getOrderProcessPrice() {
  //     console.log(2323333);
  //   }
  // },

  methods: {

    /**
     * Personal Data Update
     */
    setPopupInformationData() {
      this.changeDataPopup = [
        {
          field: 'name',
          name: 'ordersInbound_ContactName',
          type: 'text',
          value: this.PI.information.personalName,
          valid: this.PI.validation.personalName,
          validTxt: this.PI.validationTranslate.personalName,
        },
        {
          field: 'phone',
          name: 'ordersInbound_PhoneNumber',
          type: 'text',
          value: this.PI.information.personalPhone,
          valid: this.PI.validation.personalPhone,
          validTxt: this.PI.validationTranslate.personalPhone,
        },
        {
          field: 'company',
          name: 'ordersInbound_CompanyName',
          type: 'text',
          value: this.PI.information.personalCompany,
          valid: this.PI.validation.personalCompany,
          validTxt: this.PI.validationTranslate.personalCompany,
        },
      ]
    },

    personalUpdate(data) {
      this.PI.setPersonalName(this._.find(data, {field: 'name'}).value)
      this.PI.setPersonalPhone(this._.find(data, {field: 'phone'}).value)
      this.PI.setPersonalCompany(this._.find(data, {field: 'company'}).value)

      let validate = this.PI.personalInfoPartsValidate({
        personalName: true,
        personalPhone: true,
        personalCompany: true,
      })

      if(!validate){
        this.setPopupInformationData()
        return
      }

      // let prepare = this.PI.preparePersonalInformation()
      // let prepareData = {
      //   recipient_contact_name: prepare.personalName,
      //   recipient_phone_number: prepare.personalPhone,
      //   recipient_company_name: prepare.personalCompany,
      // }

      this.isModalChangeAddressPopup = false
      this.personal.setPersonalName(this._.find(data, {field: 'name'}).value)
      this.personal.setPersonalPhone(this._.find(data, {field: 'phone'}).value)
      this.personal.setPersonalCompany(this._.find(data, {field: 'company'}).value)


      // this.$store.dispatch('updateCustomerInformationOrdersFBM', {
      //   id: this.FBM.data.Order.getId(),
      //   data: prepareData
      // }).then(response => {
      //   let status = this.checkUpdateResponse(response)
      //   if(status){
      //     this.isModalChangeAddressPopup = false
      //     this.personal.setPersonalName(this._.find(data, {field: 'name'}).value)
      //     this.personal.setPersonalPhone(this._.find(data, {field: 'phone'}).value)
      //     this.personal.setPersonalCompany(this._.find(data, {field: 'company'}).value)
      //   }
      // })
    },



    /**
     * Delivery Data Update
     */
    setPopupDeliveryData() {
      this.changeDataPopup = [
        {
          field: 'address',
          name: 'ordersInbound_Address',
          type: 'text',
          value: this.Delivery.delivery.address,
          valid: this.Delivery.validation.address,
          validTxt: this.Delivery.validationTranslate.address,
        },
        {
          field: 'address2',
          name: 'common_addressSecond',
          type: 'text',
          value: this.Delivery.delivery.address2,
          valid: this.Delivery.validation.address2,
          validTxt: this.Delivery.validationTranslate.address2,
        },
        {
          field: 'city',
          name: 'ordersInbound_City',
          type: 'text',
          value: this.Delivery.delivery.city,
          valid: this.Delivery.validation.city,
          validTxt: this.Delivery.validationTranslate.city,
        },
        {
          field: 'region',
          name: 'ordersInbound_Region',
          type: 'text',
          value: this.Delivery.delivery.region,
          valid: this.Delivery.validation.region,
          validTxt: this.Delivery.validationTranslate.region,
        },
        {
          field: 'country',
          name: 'ordersInbound_Country',
          type: 'customSelect',
          value: this.Delivery.delivery.country,
          valid: this.Delivery.validation.country,
          validTxt: this.Delivery.validationTranslate.country,
        },
        {
          field: 'zip',
          name: 'ordersInbound_ZipCode',
          type: 'text',
          value: this.Delivery.delivery.zip,
          valid: this.Delivery.validation.zip,
          validTxt: this.Delivery.validationTranslate.zip,
        },
      ]
    },

    deliveryUpdate(data) {
      this.Delivery.setAddress(this._.find(data, {field: 'address'}).value)
      this.Delivery.setAddress2(this._.find(data, {field: 'address2'}).value)
      this.Delivery.setCity(this._.find(data, {field: 'city'}).value)
      this.Delivery.setRegion(this._.find(data, {field: 'region'}).value)
      this.Delivery.setCountry(this._.find(data, {field: 'country'}).value)
      this.Delivery.setZip(this._.find(data, {field: 'zip'}).value)

      let validate = this.Delivery.deliveryInfoValidate()

      if(!validate){
        this.setPopupDeliveryData()
        return
      }

      this.isModalChangeAddressPopup = false
      this.delivery.setAddress(this._.find(data, {field: 'address'}).value)
      this.delivery.setAddress2(this._.find(data, {field: 'address2'}).value)
      this.delivery.setCity(this._.find(data, {field: 'city'}).value)
      this.delivery.setRegion(this._.find(data, {field: 'region'}).value)
      this.delivery.setCountry(this._.find(data, {field: 'country'}).value)
      this.delivery.setZip(this._.find(data, {field: 'zip'}).value)

      this.$emit('reload')
    },




    /**
     * API Data Update
     */
    setPopupAPIData() {
      this.changeDataPopup = [
        {
          field: 'orderProcessPrice',
          name: 'ordersInbound_orderProcessPrice',
          type: 'select',
          value: this.ordersInbound.data.orderProcessPrice,
          valid: false,
          validTxt: false,
          options: this.ordersInbound.data.orderProcessPriceList,
          optionsLabel: 'cost',
        },
        {
          field: 'shippingPrice',
          name: 'ordersInbound_shippingPrice',
          type: 'inputSum',
          value: this.ordersInbound.data.shippingPrice,
          valid: false,
          validTxt: false,
        },
        {
          field: 'packagingPrice',
          name: 'ordersInbound_packagingPrice',
          type: 'inputSum',
          value: this.ordersInbound.data.packagingPrice,
          valid: false,
          validTxt: false,
        },
        {
          field: 'totalOrderProcessPrice',
          name: 'ordersInbound_totalOrderProcessPrice',
          type: 'inputSum',
          value: this.ordersInbound.data.totalOrderProcessPrice,
          valid: false,
          validTxt: false,
        },
      ]
    },

    APIUpdateOrderPrice(data) {
      if(this.ordersInbound.getAdminChangedTotalOrderProcessPrice() || !this.ordersInbound.getUseTotalOrderProcessPrice()) {
        return
      }

      let orderPrice = this._.find(data, {field: 'orderProcessPrice'}).value
      if(orderPrice && Number(orderPrice.cost) > 0) {
        let productCount = 0
        this.ordersInbound.data.productsForCellArray.map(product => {
          productCount += product.data.productCount
        })
        this._.find(this.changeDataPopup, {field: 'totalOrderProcessPrice'}).value = parseFloat(Number(orderPrice.cost) * productCount).toFixed(2)
        // if(productCount > 3) {
        //   this._.find(this.changeDataPopup, {field: 'totalOrderProcessPrice'}).value = parseFloat(Number(orderPrice.cost) * productCount).toFixed(2)
        // }
      } else {
        this._.find(this.changeDataPopup, {field: 'totalOrderProcessPrice'}).value = parseFloat(Number(0)).toFixed(2)
      }

    },

    APIUpdate(data) {
      this.OrdersInboundLocal.setOrderProcessPrice(this._.find(data, {field: 'orderProcessPrice'}).value)
      this.OrdersInboundLocal.setPackagingPrice(this._.find(data, {field: 'packagingPrice'}).value)
      this.OrdersInboundLocal.setShippingPrice(this._.find(data, {field: 'shippingPrice'}).value)
      this.OrdersInboundLocal.setTotalOrderProcessPrice(this._.find(data, {field: 'totalOrderProcessPrice'}).value)

      let validate = this.OrdersInboundLocal.orderAPIValidate()

      if(!validate){
        this.setPopupAPIData()
        return
      }

      this.ordersInbound.setOrderProcessPrice(this._.find(data, {field: 'orderProcessPrice'}).value)
      this.ordersInbound.setPackagingPrice(this._.find(data, {field: 'packagingPrice'}).value)
      this.ordersInbound.setShippingPrice(this._.find(data, {field: 'shippingPrice'}).value)
      this.ordersInbound.setTotalOrderProcessPrice(this._.find(data, {field: 'totalOrderProcessPrice'}).value)
      this.isModalChangeAddressPopup = false
    },


    /**
     * Comment Data Update
     */
    setPopupCommentData() {
      this.changeDataPopup = [
        {
          field: 'comment',
          name: 'fbm_CommentOptional',
          type: 'textarea',
          value: this.ordersInbound.getComment(),
          valid: this.ordersInbound.validation.comment,
          validTxt: this.ordersInbound.validationTranslate.comment,
        },
      ]
    },
    commentUpdate(data) {
      this.isModalChangeAddressPopup = false
      this.ordersInbound.setComment(this._.find(data, {field: 'comment'}).value)
    },



    checkUpdateResponse(response) {
      if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

      switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
        case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
          this.openNotify('success', 'common_notificationRecordChanged')

          return true
        }
          /**
           * Validation Error
           */
        case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
          let errors = response.response.data.errors;
          this.notifyErrorHelper(errors)
          break
        }
          /**
           * Undefined Error
           */
        default: {
          this.openNotify('error', 'common_notificationUndefinedError')
        }
      }

      return false
    },
  }
}
